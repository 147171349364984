import React from "react";
import CustomerChat from "./src/components/global/CustomerChat";
import "./src/styles/style.scss";

export const wrapPageElement = ({ element}) => {
  return (
    // <Layout name="wrapPageElement" props={{}} args={args} mode="browser">
    <React.Fragment>
      {element}
      <CustomerChat />
    </React.Fragment>
  );
};
