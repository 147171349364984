import React from 'react';

let promise;

export function useFacebook(options, cb) {
  if (typeof options === 'function') {
    cb = options;
  }

  React.useEffect(() => {
    if (promise) {
      promise.then(cb);
    } else {
      promise = new Promise(resolve => {
        window.fbAsyncInit = () => {
          window.FB.init({
            xfbml: true,
            version: "v9.0",
            ...options,
          });
          resolve(window.FB);
        };

        const script = document.createElement('script');
        script.src = `https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js`; 
        document.head.appendChild(script);
      });
      promise.then(cb);
    }
  }, []);
}
